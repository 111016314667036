(function (r, d, s) {
  r.loadSkypeWebSdkAsync =
    r.loadSkypeWebSdkAsync ||
    function (p) {
      let js,
        sjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(p.id)) {
        return;
      }
      js = d.createElement(s);
      js.id = p.id;
      js.src = p.scriptToLoad;
      js.onload = p.callback;
      sjs.parentNode.insertBefore(js, sjs);
    };
  const p = {
    scriptToLoad: "https://swx.cdn.skype.com/shared/v/latest/skypewebsdk.js",
    id: "skype_web_sdk",
  };
  r.loadSkypeWebSdkAsync(p);
})(window, document, "script");

function loadSkype() {
  window.Skype.initialize(
    { apiKey: "shareButton" },
    function () {},
    function () {}
  );
}
